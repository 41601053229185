import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Invite, InviteViewModel } from '../models/invite.model';
import firebase from 'firebase/compat';
import { FirestoreDocument } from '../models/firestore-document.model';
import { ENDPOINTS, INVITE_COLLECTION_NAME } from '../utils/constants';
import { HttpClient } from '@angular/common/http';
import { Result } from '../models/result.model';
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: 'root'
})
export class InviteService {
   private collection: AngularFirestoreCollection<Invite>;

   constructor(private afs: AngularFirestore,
      private http: HttpClient) {
      this.collection = this.afs.collection<Invite>(INVITE_COLLECTION_NAME);
   }

   set(roomDatabaseId: string, generatedId: string, reseller: string, path: string): Promise<void | DocumentReference<Invite>> {
      return this.collection.doc(roomDatabaseId).set({ path, generatedId, reseller });
   }

   add(generatedId: string, reseller: string, path: string): Promise<void | DocumentReference<Invite>> {
      return this.collection.add({ path, generatedId, reseller });
   }

   get(id: string): Promise<(Invite & FirestoreDocument | undefined)> {
      return this.collection.doc(id).ref.get()
         .then((response: firebase.firestore.DocumentSnapshot<Invite>) => {
            const data = response.data();
            return data ? { _id: response.id, ...data } : undefined;
         });
   }

   async getByGeneratedIdAndReseller(resellerId: string, generatedId: string): Promise<undefined | Invite> {
      const response = await this.collection.ref
         .where('reseller', '==', resellerId)
         .where('generatedId', '==', generatedId).get();

      if (!response.empty) {
         return response.docs[0].data();
      }

      return undefined;
   }

   getForJoinOrganization(id: string) {
      return this.http.get<Result<InviteViewModel | undefined>>(`${environment.api}${ENDPOINTS.invites}/${id}`);
   }
}
