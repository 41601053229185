import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Language } from '../models/language.model';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private _languages: Language[] = [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' },
  ];

  get languages() {
    return this._languages;
  }

  constructor(private translate: TranslateService) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  setLocale(value: string) {
    this.translate.use(value);
  }
}
