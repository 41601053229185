import { Injectable } from '@angular/core';

import { Subscription, timer } from 'rxjs';

import { PageVisibilityService } from './page-visibility.service';

@Injectable({
   providedIn: 'root'
})
export class NotificationService {
   private originalTitle: string = document.title;
   private flashSubscription: Subscription | undefined;

   constructor(private visibilityService: PageVisibilityService) {}

   show(
      title: string,
      text: string,
      icon: 'success' | 'warning' | 'error' | 'info' = 'success',
      duration: number = 3000
   ) {
      let existingDiv = document.getElementById('module-notification');
      if (existingDiv) existingDiv.remove();

      let notificationDiv = document.createElement('div');
      notificationDiv.id = 'module-notification';
      notificationDiv.style.display = 'block';
      notificationDiv.style.opacity = '0';
      notificationDiv.style.position = 'absolute';
      notificationDiv.style.zIndex = '999';

      let iconClass: string = 'box-notification';

      switch (icon) {
         case 'warning':
            iconClass += ' warning-notification';
            break;
         case 'info':
            iconClass += ' information-notification';
            break;
         case 'error':
            iconClass += ' error-notification';
            break;
      }

      let notificationHTML = `
    <div class="${iconClass}">
         <i class="icon-notification"></i>
         <div>
           <h2>${title}</h2>
           <span>${text}</span>

         </div>
       </div>
    `;
      // <button type="button" class="button-close-notification"></button>

      notificationDiv.innerHTML = notificationHTML;

      document.body.appendChild(notificationDiv);

      let fadeInEffect = setInterval(() => {
         notificationDiv.style.opacity = (Number(notificationDiv.style.opacity) + 0.1).toString();

         if (notificationDiv.style.opacity == '1') {
            clearInterval(fadeInEffect);

            setTimeout(() => {
               let fadeOutEffect = setInterval(() => {
                  notificationDiv.style.opacity = (
                     Number(notificationDiv.style.opacity) - 0.1
                  ).toString();

                  if (notificationDiv.style.opacity == '0') {
                     notificationDiv.remove();
                     clearInterval(fadeOutEffect);
                  }
               }, 50);
            }, duration);
         }
      }, 50);
   }

   close() {
      let notificationDiv = document.getElementById('module-notification');
      if (notificationDiv) notificationDiv.remove();
   }

   public requestNotificationPermission() {
      if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
         Notification.requestPermission();
         // Notification.requestPermission().then(permission => {
         //    if (permission === 'granted') {
         //       this.showNotification('Thank you for allowing notifications!');
         //    }
         // });
      }
   }

   private showNotification(title: string, message: string, iconUrl?: string) {
      if (Notification.permission === 'granted') {
         const options = {
            body: message,
            icon: iconUrl ? iconUrl : '/assets/images/ohio-logo.png' // Use a default icon or provide a custom one
         };
         const notification = new Notification(title, options);

         notification.onclick = event => {
            event.preventDefault(); // Prevent the browser from focusing the Notification’s tab
            this.focusOrOpenTab();
         };
      } else {
         this.requestNotificationPermission();
      }
   }

   private focusOrOpenTab() {
      const url = window.location.href;
      const windows = window.open('', '_self');

      if (windows) {
         windows.focus();
      } else {
         window.open(url, '_blank');
      }
   }

   public notifyUser(title: string, message: string, logoUrl?: string) {
      if (!this.visibilityService.isPageVisible()) {
         this.showNotification(title, message, logoUrl);
      }
   }
}
