import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ENDPOINTS, RESELLER_ID } from '../utils/constants';
// TEMP_RESELLER_ID

@Injectable({
  providedIn: 'root'
})
export class ResellerService {
  // _resellerId: string | undefined = TEMP_RESELLER_ID;

  constructor(private http: HttpClient) { }

  getReseller() {
    //return TEMP_RESELLER_ID;
    return localStorage.getItem(RESELLER_ID);
  }

  setReseller(resellerId: string) {
    // return localStorage.setItem(RESELLER_ID, TEMP_RESELLER_ID);
    return localStorage.setItem(RESELLER_ID, resellerId);
  }

  getIdFromAPI() {
    return this.http.get(`${environment.api}/${ENDPOINTS.resellers}`);
  }
}
