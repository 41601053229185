import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import es from '@angular/common/locales/es';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { GhButtonModule } from '@ctrl/ngx-github-buttons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ImagekitioAngularModule } from 'imagekitio-angular';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';

import { environment } from '../environments/environment';
import { CodemirrorModule } from '../lib/public_api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EditorComponent } from './editor/editor.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ResellerInterceptor } from './interceptors/reseller.interceptor';
import { HomeLayoutComponent } from './shared/layout/home-layout/home-layout.component';
import { HomeNavbarComponent } from './shared/layout/home-layout/home-navbar/home-navbar.component';

registerLocaleData(en);
registerLocaleData(es);

export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http);
}

// export function initializeFirebaseApp() {
//    return () => {
//       try {
//          AngularFireModule.initializeApp(environment.firebase);
//       } catch (error) {
//          console.error('Firebase initialization failed:', error);
//       }
//    };
// }

@NgModule({
   declarations: [AppComponent, EditorComponent, HomeLayoutComponent, HomeNavbarComponent],
   exports: [],
   imports: [
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAuthModule,
      provideFirestore(() => getFirestore()),
      provideStorage(() => getStorage()),
      TranslateModule.forRoot({
         loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
         }
      }),
      ImagekitioAngularModule.forRoot({
         publicKey: environment.imagekit.publicKey,
         urlEndpoint: environment.imagekit.urlEndpoint
      }),
      BrowserModule,
      FormsModule,
      CodemirrorModule,
      GhButtonModule,
      AppRoutingModule,
      HttpClientModule,
      ReactiveFormsModule,
      NgxQrcodeStylingModule
   ],
   providers: [
      // { provide: APP_INITIALIZER, useFactory: initializeFirebaseApp, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ResellerInterceptor, multi: true }
   ],
   bootstrap: [AppComponent]
})
export class AppModule {}
