import { Injectable } from '@angular/core';
import { Organization, OrganizationViewModel } from '../models/organization.model';
import { HttpClient } from '@angular/common/http';
import { Result } from '../models/result.model';
import { environment } from '../../environments/environment';
import { ENDPOINTS, LOCAL_STORAGE_KEYS } from '../utils/constants';
import { SetProfileDto } from '../models/profile/profile.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(private http: HttpClient) {
  }

  get(id: string) {
    return this.http.get<Result<OrganizationViewModel | undefined>>(
      `${environment.api}${ENDPOINTS.organizations}/${id}`
    );
  }

  setProfile(organization: string, dto: SetProfileDto) {
    return this.http.post(
      `${environment.api}${ENDPOINTS.organizations}/${organization}${ENDPOINTS.profiles}`,
      dto
    );
  }

  deactivateInvite(organization: string, invite: string) {
    return this.http.patch(
      `${environment.api}${ENDPOINTS.organizations}/${organization}${ENDPOINTS.invites}/${invite}`,
      {}
    );
  }

  getOrganizationId() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE_CURRENT_ORGANIZATION_ID);
  }
}
