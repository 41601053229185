import { NgModule } from '@angular/core';
import {
   canActivate,
   redirectLoggedInTo,
   redirectUnauthorizedTo
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';

import { EditorComponent } from './editor/editor.component';
import { HomeLayoutComponent } from './shared/layout/home-layout/home-layout.component';
import { DEVICE_SETTINGS_URL_PATH, JOIN_MEETING_URL_PATH } from './utils/constants';

const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);
const redirectUnauthorizedToSignIn = () => redirectUnauthorizedTo(['sign-in']);

const routes: Routes = [
   { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
   {
      path: 'home',
      component: HomeLayoutComponent,
      children: [
         {
            path: 'rooms',
            loadChildren: () =>
               import('../app/pages/room-list/room-list.module').then(m => m.RoomListModule)
         },
         {
            path: 'presentations/:id',
            loadChildren: () =>
               import('./pages/presentation-management/presentation-management.module').then(
                  m => m.PresentationManagementModule
               )
         },
         { path: '', redirectTo: 'rooms', pathMatch: 'full' }
      ],
      ...canActivate(redirectUnauthorizedToSignIn)
   },
   {
      path: 'sign-in',
      loadChildren: () =>
         import('../app/pages/auth/sign-in/sign-in.module').then(m => m.SignInModule),
      ...canActivate(redirectLoggedInToHome)
   },
   {
      path: 'sign-up',
      loadChildren: () =>
         import('../app/pages/auth/sign-up/sign-up.module').then(m => m.SignUpModule)
   },
   {
      path: 'forgot-password',
      loadChildren: () =>
         import('../app/pages/auth/forgot-password/forgot-password.module').then(
            m => m.ForgotPasswordModule
         )
   },
   {
      path: 'reset-password',
      loadChildren: () =>
         import('../app/pages/auth/reset-password/reset-password.module').then(
            m => m.ResetPasswordModule
         )
   },
   {
      path: 'editor',
      component: EditorComponent
   },
   {
      path: DEVICE_SETTINGS_URL_PATH,
      loadChildren: () =>
         import('../app/pages/device-settings/device-settings.module').then(
            m => m.DeviceSettingsModule
         )
   },
   {
      path: `${DEVICE_SETTINGS_URL_PATH}/:roomId`,
      loadChildren: () =>
         import('../app/pages/device-settings/device-settings.module').then(
            m => m.DeviceSettingsModule
         )
   },
   {
      path: 'removed',
      loadChildren: () =>
         import('../app/pages/kicked-user/kicked-user.module').then(m => m.KickedUserModule)
   },
   {
      path: 'session-closed',
      loadChildren: () =>
         import('../app/pages/session-closed/session-closed.module').then(m => m.SessionClosedModule)
   },
   {
      path: 'room/:roomId',
      loadChildren: () => import('../app/pages/room/room.module').then(m => m.RoomModule)
   },
   {
      path: ':roomId',
      loadChildren: () => import('../app/pages/room/room.module').then(m => m.RoomModule)
   },
   {
      path: 'presentation-local/:roomId',
      loadChildren: () => import('../app/pages/room/room.module').then(m => m.RoomModule)
   },
   {
      path: 'presentation/:id',
      loadChildren: () =>
         import('./pages/presentation/presentation.module').then(m => m.PresentationModule)
   },
   {
      path: `${JOIN_MEETING_URL_PATH}/:roomId`,
      loadChildren: () =>
         import('../app/pages/device-settings/device-settings.module').then(
            m => m.DeviceSettingsModule
         )
   },
   {
      path: 'admin',
      loadChildren: () =>
         import('../app/pages/redirects/admin-redirect/admin-redirect.module').then(
            m => m.AdminRedirectModule
         )
   },
   { path: '**', redirectTo: 'sign-in' }
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule]
})
export class AppRoutingModule { }
