import {
  AfterContentChecked,
  AfterViewChecked,
  Component,
  HostListener,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { ResellerService } from './services/reseller.service';
import { LOCAL_STORAGE_KEYS, PAGE_LOADED, RESELLER_ID } from './utils/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public isLoading: boolean = false;

  constructor(
    private resellerService: ResellerService,
    private authService: AuthService,
    private afAuth: AngularFireAuth
  ) { }

  evalLoading() {
    let loaded = localStorage.getItem(PAGE_LOADED);

    if (loaded) {
      this.isLoading = false;
    }
  }

  async ngOnInit() {
    // localStorage.setItem(PAGE_LOADED, 'ok');
    // this.evalLoading();

    if (environment.production) {
      window.console.log = function () { };
    }

    this.resellerService.getIdFromAPI().subscribe({
      next: (response: any) => {
        if (response.message == 'success') {
          this.resellerService.setReseller(response.data._id);
        }
      },
      error: error => {
        console.error(error);
        console.error(error.message);
      }
    });

    if (!location.toString().includes('presentation') && environment.production) {
      this.afAuth.user.subscribe(data => {
        if (data) {
          this.authService.listenClientSessionIdUpdates(data.uid).subscribe({
            next: (data: any) => {
              if (data) {
                const localClientSessionId = localStorage.getItem(
                  LOCAL_STORAGE_KEYS.CLIENT_SESSION_ID
                );

                if (
                  !localClientSessionId ||
                  data.clientSessionId !== localClientSessionId
                ) {
                  this.authService.signOut();
                  localStorage.setItem(
                    LOCAL_STORAGE_KEYS.SESSION_EXPIRED,
                    'session-expired'
                  );
                  location.assign('/sign-in?');
                }
              }
            }
          });
        }
      });
    }
  }
}
