import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccessRequestViewModel } from '../../app/models/access-request.model';
import { Result } from '../../app/models/result.model';
import { ENDPOINTS } from '../../app/utils/constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccessRequestService {
  constructor(private http: HttpClient) {}

  list(profile: string) {
    const params = new HttpParams().set('profile', profile);
    return this.http.get<Result<AccessRequestViewModel[]>>(
      `${environment.api}${ENDPOINTS.accessRequests}`,
      { params }
    );
  }

  update(id: string, profile: string, status: 'approved' | 'denied') {
    return this.http.put<Result>(`${environment.api}${ENDPOINTS.accessRequests}/${id}`, {
      profile,
      status,
    });
  }
}
