<div id="components-loading" *ngIf="isLoading">
   <div class="row-loading">
      <div class="column-loading">
         <img src="assets/images/icon-loading.gif" alt=""  class="imageloading"/>
         <span>
            Loading...
         </span>
      </div>
   </div>
</div>
<router-outlet></router-outlet>