export const environment = {
   production: false,
   admin: 'https://admin-dev2.linkroom.com',
   vipapi: 'https://vip-admin-api-dev.linkroom.com',
   api: 'https://admindev-api.linkroom.com',
   authDomain: 'https://dev.linkroom.com/__/auth/handler',
   commemorative_photo_url: 'https://us-central1-linkroom-v2-dev.cloudfunctions.net/api',
   enablePixelStreaming: true,
   firebase: {
      projectId: 'linkroom-v2-dev',
      appId: '1:383066649579:web:19c4ead8fa9b62525a1fae',
      storageBucket: 'linkroom-v2-dev.appspot.com',
      apiKey: 'AIzaSyAmHvWxoIoLv41qJVXeIGlo6Y-Jbn_K6DI',
      authDomain: 'dev.linkroom.com',
      messagingSenderId: '383066649579',
      measurementId: 'G-5HW1Y5YHWW'
   },
   gallery: 'https://gallery-dev.linkroom.com',
   imagekit: {
      publicKey: 'public_HaXtq7gDdr1WTjhEhsMpoKTpp+8=',
      urlEndpoint: 'https://ik.imagekit.io/b152q4ah5/linkroom-dev'
   },
   pixel: 'https://linkroomv2-ps.web.app/',
   AGORA_APP_ID: 'b41999bfe8494271a9577b3bfcc2e08b',
   INDICATIVE_API_KEY: '73e05edc-4b55-4043-905c-aff9ef6a9f50',
   PHOTON_APP_ID: '29fe702e-40fd-4f02-b3c6-246835f618af'
};
