import { FormGroup } from '@angular/forms';

import { CRYPTO_SEED } from './constants';

const CryptoJS = require('crypto-js');

export const valueMustMatch = (controlName: string, matchingControlName: string) => {
   return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
         return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
         matchingControl.setErrors({ mustMatch: true });
      } else {
         matchingControl.setErrors(null);
      }
      return null;
   };
};

export const encryptData = (value: string): string => {
   const key = CRYPTO_SEED;
   const keyutf = CryptoJS.enc.Utf8.parse(key);
   const iv = CryptoJS.enc.Base64.parse(key);
   const enc = CryptoJS.AES.encrypt(value, keyutf, { iv: iv });

   return enc.toString();
};

export const decryptData = (data: string): any => {
   try {
      const key = CRYPTO_SEED;
      const keyutf = CryptoJS.enc.Utf8.parse(key);
      const iv = CryptoJS.enc.Base64.parse(key);

      const bytes = CryptoJS.AES.decrypt(data, keyutf, { iv: iv });
      if (bytes.toString()) {
         return bytes.toString(CryptoJS.enc.Utf8);
      }
      return data;
   } catch (e) {
      console.error(e);
   }
};

export const encryptOauth = (base: string, secret: string) => {
   return CryptoJS.HmacSHA1(base, secret);
};

export const stringSignature = (signature: string): string => {
   return CryptoJS.enc.Base64.stringify(signature);
};

export const generateSignature = (
   method: string,
   url: string,
   params: any,
   consumerSecret: string,
   tokenSecret: string
): string => {
   const paramString = Object.keys(params)
      .sort()
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');

   const baseString = `${method.toUpperCase()}&${encodeURIComponent(url)}&${encodeURIComponent(
      paramString
   )}`;
   const signingKey = `${encodeURIComponent(consumerSecret)}&${encodeURIComponent(tokenSecret)}`;
   const signature = CryptoJS.HmacSHA1(baseString, signingKey).toString(CryptoJS.enc.Base64);

   return signature;
};

export const getProperCaseText = (text: string) => {
   // let words: string[] = text.toLowerCase().split(' ');

   // for (let index = 0; index < words.length; index++) {
   //   words[index] = `${words[index][0].toUpperCase()}${words[index].slice(1)}`;
   // }

   // return words.join(' ').trim();

   try {
      let nameWords: string[] = text.split(' ');
      let finalName: string = '';

      nameWords.forEach(w => {
         finalName += `${w[0].toUpperCase()}${w.substring(1, w.length).toLowerCase()} `;
      });

      return finalName.trim();
   } catch (error: any) {
      return text;
   }
};

export const getRandomColor = () => {
   return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export const isInvalidField = (formGroup: FormGroup, element: any) => {
   const name = element.getAttribute('formControlName');
   return formGroup.get(name)?.invalid && formGroup.get(name)?.touched;
};

export const getInvalidFieldMessage = (formGroup: FormGroup, element: any) => {
   if (isInvalidField(formGroup, element)) {
      const name = element.getAttribute('formControlName');
      const errors = formGroup.get(name)?.errors;

      if (errors) {
         const errorType = Object.keys(errors)[0];

         switch (errorType) {
            case 'min':
            case 'required':
               return 'Required';
            case 'email':
               return 'Invalid email format';
            case 'minlength':
               return `Enter at least ${errors[errorType]['requiredLength']} characters`;
            case 'maxlength':
               return `Enter ${errors[errorType]['requiredLength']} characters at max`;
            case 'pattern':
               return 'Invalid format';
            default:
               return errorType;
         }
      }
   }

   return '';
};

export const getCurrentHourMinute = (): string => {
   const currentDate = new Date();
   const hours: string = currentDate.getHours().toString().padStart(2, '0');
   const minutes: string = currentDate.getMinutes().toString().padStart(2, '0');

   return `${hours}:${minutes}`;
};

export const getCurrentLocalTime = (): string => {
   return new Date().toLocaleTimeString().split(' ').pop()!;
};

export const getFullHourFromDate = (date: Date | undefined = undefined): Number => {
   if (!date) date = new Date();
   const hours: string = date.getHours().toString().padStart(2, '0');
   const minutes: string = date.getMinutes().toString().padStart(2, '0');
   const seconds: string = date.getSeconds().toString().padStart(2, '0');
   return Number(`${hours}${minutes}${seconds}`);
};

export const getParsedMessage = (message: string): string => {
   let parsedMessage: string = '';
   let pattern: RegExp | string = '^([a-zA-Z0-9]+(.[a-zA-Z0-9]+)+.*)$';
   let patternTest = /^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/;

   if (patternTest.test(message) && message.substring(0, 3) != 'www') {
      parsedMessage = `<a href="${message}" target="_blank">${message}</a>`;
   } else {
      let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      let text1 = message.replace(exp, `<a href='$1' target="_blank">$1</a>`);
      pattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

      parsedMessage = text1.replace(pattern, `$1<a target="_blank" href="http://$2">$2</a>`);
   }

   return parsedMessage;
};

export const getInitials = (name: string): string => {
   return (name.length > 1 ? name.substring(0, 2) : name).toUpperCase();
};

export const generateRoomUUID = (): string => {
   const segmentsLength = [3, 4, 3];
   let idSegments = [];

   for (let j = 0; j < segmentsLength.length; j++) {
      let segment = '';
      for (let k = 0; k < segmentsLength[j]; k++) {
         const charCode = Math.floor(Math.random() * (122 - 97 + 1)) + 97; // Random ASCII code for 'a' (97) to 'z' (122)
         segment += String.fromCharCode(charCode);
      }
      idSegments.push(segment);
   }

   return idSegments.join('-');
};

export const readFile = (file: File): Promise<string> => {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
         resolve(e.target.result);
      };

      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
   });
};
