import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResellerService } from '../services/reseller.service';
import { REQUEST_HEADERS } from '../utils/constants';
import { OrganizationService } from '../services/organization.service';

@Injectable()
export class ResellerInterceptor implements HttpInterceptor {
   constructor(
      private resellerService: ResellerService,
      private organizationService: OrganizationService
   ) { }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const resellerId: string | null = this.resellerService.getReseller();
      const organizationId = this.organizationService.getOrganizationId();

      if (!resellerId) return next.handle(request);

      const headers = organizationId
         ? request.headers.set(REQUEST_HEADERS.RESELLER, resellerId).set(REQUEST_HEADERS.ORGANIZATION, organizationId)
         : request.headers.set(REQUEST_HEADERS.RESELLER, resellerId);

      const clone = request.clone({ headers });

      return next.handle(clone);
   }
}
