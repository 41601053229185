import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/css-hint';

import * as Acorn from 'acorn';

const defaults = {
  markdown:
    '# Heading\n\nSome **bold** and _italic_ text\nBy [Scott Cooper](https://github.com/scttcper)',
  'text/typescript': `class Car {
    constructor(make, model) {
      this.make = make;
      this.model = model;
    }
}`,
};

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {
 // @ViewChild('editor') editor;
 @ViewChild('hello', { static: false }) divHello: ElementRef | undefined;

  constructor() { 
   
  }
  
  ngOnInit(): void {

  }

  

  readOnly = false;
  mode: keyof typeof defaults = 'text/typescript';

  options = {
    lineNumbers: true,
    mode: this.mode,
    extraKeys : {"Ctrl-Space" : "autocomplete"}
  };
  
  defaults = defaults;
  textCode = ""+this.defaults[this.mode];

  changeMode(): void {
    this.options = {
      ...this.options,
      mode: this.mode,
    };
    this.textCode = ""+this.defaults[this.mode];
  }

  handleChange($event: Event): void {
    this.textCode = ""+$event;
  }

  clear(): void {
    this.defaults[this.mode] = '';
  }
  executeCode(): void {
    var acorn = require("acorn");
    let parsed = acorn.parse(this.textCode, {ecmaVersion: 2020});
    
    let classDecl = parsed.body[0];

    const ctrDef = classDecl.body.body.find((node: { kind: string; }) => node.kind === 'constructor');
  }
  
}
