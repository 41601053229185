export enum FirebaseAuthMethod {
   EMAIL = 'email',
   GOOGLE = 'google',
   FACEBOOK = 'facebook',
}

export enum ShortcutType {
   OPEN_ROOM_MENU = 'open_room_menu',
   TOGGLE_MICROPHONE = 'toggle_microphone',
   TOGGLE_CAMERA = 'toggle_camera'
}

export enum EventUserStatus {
   PENDING = 0,
   ONGOING = 1,
   DONE = 2,
   REMOVED = 3
}

export enum RoomUserStatus {
   CONNECTING = 'connecting',
   CONNECTED = 'connected',
   DISCONNECTED = 'disconnected'
}