<div
  *ngIf="viewingAs"
  style="
    background-color: tomato;
    color: white;
    font: 0.885vw/1vw poppins-regular;
    padding: 0.75rem;
    text-align: center;
  "
>
  Viewing as: {{ viewingAs }} - Time remaining: {{ countdown }}
</div>

<div id="components-room-list">
  <div class="row-room-list">
    <app-home-navbar></app-home-navbar>
    <router-outlet></router-outlet>
  </div>
</div>
