import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Result } from '../../app/models/result.model';
import { SupportAccessViewModel } from '../../app/models/support-access.model';
import { ENDPOINTS } from '../../app/utils/constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupportAccessService {
  constructor(private http: HttpClient) {}

  get(id: string) {
    return this.http.get<Result<SupportAccessViewModel | undefined>>(`${environment.api}${ENDPOINTS.supportAccess}/${id}`);
  }
}
