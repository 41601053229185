import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Result } from '../models/result.model';
import { RoleViewModel } from '../models/role.model';
import { ENDPOINTS } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private http: HttpClient) { }


  get(id: string) {
    return this.http.get<Result<RoleViewModel | undefined>>(`${environment.api}${ENDPOINTS.roles}/${id}`);
  }
}
