import { Injectable } from '@angular/core';
import { Profile, ProfileNameEmail } from '../models/profile/profile.model';
import { FirestoreDocument } from '../models/firestore-document.model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Organization, SetOrganizationDto } from '../models/organization.model';
import { ResellerService } from './reseller.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ENDPOINTS } from '../utils/constants';
import { Result } from '../models/result.model';

@Injectable({
   providedIn: 'root'
})
export class ProfileService {
   private collection: AngularFirestoreCollection<Profile> | undefined;

   constructor(private afs: AngularFirestore,
      private http: HttpClient,
      private resellerService: ResellerService) {
   }

   setCollection() {
      let resellerId = this.resellerService.getReseller();
      this.collection = this.afs.collection<Profile>(`resellers/${resellerId}/profiles`);
   }

   // get_old(id: string): Promise<(Profile & FirestoreDocument) | undefined> {
   //    this.setCollection();
   //    return this.collection!.doc(id).ref.get()
   //       .then((response: firebase.firestore.DocumentSnapshot<Profile>) => {
   //          const data = response.data();
   //          return data ? { _id: response.id, ...data } : undefined;
   //       });
   // }


   // add(profile: (Profile & FirestoreDocument)) {
   //   this.setCollection();
   //   let data = JSON.parse(JSON.stringify(profile));
   //   return this.collection!.doc(profile._id).set(data);
   // }

   patch(id: string, data: any) {
      this.setCollection();
      let json = JSON.parse(JSON.stringify(data));
      return this.collection!.doc(id).set(json, { merge: true });
   }

   getOrganizations(id: string): Promise<(Organization & FirestoreDocument)[]> {
      this.setCollection();
      return this.collection!.doc(id).collection<Organization>('organizations').ref.get()
         .then((response: firebase.firestore.QuerySnapshot<Organization>) => {
            const organizations: (Organization & FirestoreDocument)[] = response.docs.map(x => ({
               _id: x.id,
               ...x.data()
            }));

            return organizations;
         });
   }

   getOrganization(id: string, organizationId: string): Promise<(Organization & FirestoreDocument) | undefined> {
      this.setCollection();
      return this.collection!.doc(id).collection<Organization>('organizations').doc(organizationId).ref.get()
         .then((response: firebase.firestore.DocumentSnapshot<Organization>) => {
            const data = response.data();
            return data ? { _id: response.id, ...data } : undefined;
         });
   }

   get(id: string) {
      return this.http.get<Result<Profile | undefined>>(`${environment.api}${ENDPOINTS.profiles}/${id}`);
   }

   add(data: Profile) {
      return this.http.post<Result<any>>(`${environment.api}${ENDPOINTS.profiles}`, data);
   }

   setOrganization(profile: string, dto: SetOrganizationDto) {
      return this.http.post(`${environment.api}${ENDPOINTS.profiles}/${profile}${ENDPOINTS.organizations}`, dto);
   }

   getProfilesByOrganization(organizationId:string) {
      return this.http.get<Result<ProfileNameEmail[] | undefined>>(`${environment.api}${ENDPOINTS.profiles}${ENDPOINTS.organizations}/${organizationId}`);
   }
}
