import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, take } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { AccessRequestViewModel } from '../../../../models/access-request.model';
import { FirestoreDocument } from '../../../../models/firestore-document.model';
import { PresentationViewModel } from '../../../../models/presentation.model';
import { Profile, ProfileNameEmail } from '../../../../models/profile/profile.model';
import { AccessRequestService } from '../../../../services/access-request.service';
import { AuthService } from '../../../../services/auth.service';
import { I18nService } from '../../../../services/i18n.service';
import { InviteService } from '../../../../services/invite.service';
import { NotificationService } from '../../../../services/notification.service';
import { OrganizationService } from '../../../../services/organization.service';
import { PresentationService } from '../../../../services/presentation.service';
import { ProfileService } from '../../../../services/profile.service';
import { RoleService } from '../../../../services/role.service';
import {
   DEFAULT_APPLICATION_LOGO_PATH,
   DEFAULT_LANGUAGE,
   LOCAL_STORAGE_KEYS
} from '../../../../utils/constants';
import { getInitials, getInvalidFieldMessage, isInvalidField } from '../../../../utils/functions';

declare let window: any;

@Component({
   selector: 'app-home-navbar',
   templateUrl: './home-navbar.component.html',
   styleUrls: ['./home-navbar.component.css']
})
export class HomeNavbarComponent implements OnInit {
   public formGroupAccount!: FormGroup;
   public formGroupInvitation!: FormGroup;

   public showAccountOptions: boolean = false;
   public isAccountUpdating: boolean = false;
   public isCheckingInvitation: boolean = false;
   public isSaUser: boolean = false;
   public profileLoaded: boolean = false;

   public hasOrganizations: boolean = false;
   public hasLicense: boolean = false;
   public permissions: string[] = [];

   public selectedLanguage: string = 'en';
   public userName: string = '';
   public userEmail: string = '';
   public displayPresentation: boolean = !environment.production;

   public profile: (Profile & FirestoreDocument) | undefined;

   public accessRequests: AccessRequestViewModel[] = [];

   public presentations: PresentationViewModel[] = [];
   public sharedPresentationsWithMe: PresentationViewModel[] = [];

   public selectedPresentation: PresentationViewModel | undefined;

   public companyLogoUrl: string = '';

   constructor(
      private formBuilder: FormBuilder,
      public authService: AuthService,
      public profileService: ProfileService,
      public inviteService: InviteService,
      public organizationService: OrganizationService,
      private roleService: RoleService,
      private router: Router,
      private auth: AngularFireAuth,
      private zone: NgZone,
      private notificationService: NotificationService,
      private accessRequestService: AccessRequestService,
      private translateService: TranslateService,
      private i18nService: I18nService,
      private presentationService: PresentationService
   ) {}

   ngOnInit() {
      window['navBarComponentReference'] = {
         zone: this.zone,
         setUserIsSa: () => this.setUserIsSa(),
         setUserHasOrganizations: () => this.setUserHasOrganizations(),
         setUserHasLicense: () => this.setUserHasLicense(),
         setLogo: (url: string) => (this.companyLogoUrl = url),
         setProfileData: () => this.setProfileData()
      };

      document.addEventListener('click', e => {
         let element: any = e.target;
         let className: string | undefined = element.className;
         let validClasses = ['toggleUserOptions', 'username'];

         if (!className || !validClasses.includes(className)) {
            if (this.showAccountOptions) {
               this.toggleAccountOptions();
            }
         }
      });

      const localLang =
         localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE) || DEFAULT_LANGUAGE;
      this.setLanguage(localLang);
      this.selectedLanguage = localLang;
   }

   showSelectLanguage(show: boolean) {
      document.getElementById('languageForm')!.style.display = show ? '' : 'none';
   }

   setLanguage(language: string) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE, language);
      this.i18nService.setLocale(language);
      this.showSelectLanguage(false);
   }

   setUserIsSa() {
      try {
         let profileData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE_DATA) || '');
         let profile = profileData as Profile & FirestoreDocument;

         this.isSaUser = profile.sa || false;
         this.profileLoaded = true;
      } catch (error: any) {
         this.notificationService.show('Linkroom', 'No profile data provided', 'warning');
         this.authService.signOut();
         location.href = '/';
      }
   }

   setUserHasOrganizations() {
      this.hasOrganizations = true;
   }

   setUserHasLicense() {
      this.hasLicense = true;

      if (this.hasLicense) {
         const permissionData = localStorage.getItem(LOCAL_STORAGE_KEYS.PERMISSIONS_LIST)!;
         const profilePermissions = JSON.parse(atob(permissionData).toUpperCase());
         const profilePermissionKeys = Object.keys(profilePermissions);

         for (const key of profilePermissionKeys) {
            this.permissions.push(profilePermissions[key]);
         }
      }
   }

   showProfilePermissions(show: boolean) {
      document.getElementById('licensePermissions')!.style.display = show ? '' : 'none';
      this.hideMainScrollBar(show);
   }

   hideMainScrollBar(hide: boolean) {
      document.body.style.overflow = hide ? 'hidden' : '';
   }

   promptOrganizationSelect() {
      window['roomListComponentReference'].zone.run(() =>
         window['roomListComponentReference'].openOrganizationSelect()
      );
   }

   async galleryRedirect() {
      try {
         const user = await lastValueFrom(this.auth.user.pipe(take(1)));
         if (!user) return;

         const token = await user.getIdToken();
         const redirectUrl = window.location.origin;
         let url = `${environment.gallery}/auth?token=${token}&redirectUrl=${redirectUrl}`;
         const organization = localStorage.getItem(
            LOCAL_STORAGE_KEYS.PROFILE_CURRENT_ORGANIZATION_ID
         );
         if (organization) url += `&organization=${organization}`;
         window.open(url, '_blank');
      } catch (error) {
         console.error(error);
      }
   }

   async pixelRedirect() {
      try {
         const user = await lastValueFrom(this.auth.user.pipe(take(1)));
         if (!user) return;

         const token = await user.getIdToken();
         let url = `${environment.pixel}/auth?token=${token}`;
         const organization = localStorage.getItem(
            LOCAL_STORAGE_KEYS.PROFILE_CURRENT_ORGANIZATION_ID
         );
         if (organization) url += `&organization=${organization}`;
         window.open(url, '_blank');
         // window.location.href = url;
      } catch (error) {
         console.error(error);
      }
   }

   goToAdmin() {
      (window as Window).open(environment.admin);
   }

   setProfileData() {
      let data = localStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE_DATA) || '';

      if (data && data.length > 0) {
         let profileData = JSON.parse(data);
         this.profile = profileData as Profile & FirestoreDocument;
      }

      this.setUserName();
   }

   async setUserName() {
      let user = await this.authService.getCurrentUserAsync();
      let firstName: string = '';
      let lastName: string = '';

      if (this.profile) {
         firstName = this.profile.firstName || '';
         lastName = this.profile.lastName || '';
      }

      if (firstName.concat(lastName).length > 0) {
         this.userName = `${firstName} ${lastName}`;
      } else {
         this.userName = (user ? (user.displayName ? user.displayName : user.email) : '') || '';
      }

      this.userEmail = (user ? (user.email ? user.email : user.email) : '') || '';

      // if (this.userName.length > 0) {
      //   if (this.userName.includes('@')) {
      //     this.userName = this.userName.split('@')[0];
      //   } else {
      //     this.userName = this.userName.split(' ')[0];
      //   }
      // }
   }

   async promptNewName() {
      this.buildAccountForm();
      this.showAccountForm(true);
   }

   async promptInvitation() {
      this.buildInvitationForm();
      this.showInvitationForm(true);
   }

   async evalInvitationCode() {
      if (this.formGroupInvitation.invalid) {
         this.formGroupInvitation.markAllAsTouched();
         return;
      }

      this.isCheckingInvitation = true;

      try {
         const invite = await lastValueFrom(
            this.inviteService.getForJoinOrganization(this.formGroupInvitation.value.code)
         );

         if (!invite.data) {
            this.notificationService.show(
               'Invitation',
               'There was a problem trying to get the invite data.',
               'warning'
            );
            return;
         }

         if (!invite.data.active) {
            this.notificationService.show(
               'Invitation',
               'The invitation has been already used.',
               'warning'
            );
            return;
         }

         const [organization, role] = await Promise.all([
            lastValueFrom(this.organizationService.get(invite.data.organization!)),
            lastValueFrom(this.roleService.get(invite.data.role!))
         ]);

         if (
            !organization.data ||
            !role.data ||
            this.authService.user?.email !== invite.data.email
         ) {
            this.notificationService.show(
               'Invitation',
               'The invitation is invalid or has expired.',
               'warning'
            );
            return;
         }

         let profileData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE_DATA) || '');
         let profile = profileData as Profile & FirestoreDocument;

         await Promise.all([
            lastValueFrom(
               this.profileService.setOrganization(this.authService.user!.uid, {
                  _id: organization.data._id,
                  name: organization.data.name,
                  roleId: role.data._id,
                  roleName: role.data.name
               })
            ),

            lastValueFrom(
               this.organizationService.setProfile(organization.data._id!, {
                  _id: this.authService.user?.uid,
                  active: profile.active,
                  email: profile.email,
                  firstName: profile.firstName,
                  lastName: profile.lastName
               })
            ),
            lastValueFrom(
               this.organizationService.deactivateInvite(organization.data._id!, invite.data._id!)
            )
         ]);

         this.notificationService.show(
            'Invitation',
            'You just joined the organization. Reloading...',
            'success'
         );

         setTimeout(() => {
            localStorage.setItem(
               LOCAL_STORAGE_KEYS.PROFILE_CURRENT_ORGANIZATION_ID,
               organization.data!._id!
            );
            localStorage.setItem(
               LOCAL_STORAGE_KEYS.PROFILE_CURRENT_ORGANIZATION_NAME,
               organization.data!.name!
            );

            location.reload();
         }, 2500);
      } catch (error) {
         console.error(`===error====`);
         console.error(error);
         this.notificationService.show(
            'Invitation',
            'There was a problem trying to get the invite data',
            'warning'
         );
      } finally {
         this.isCheckingInvitation = false;
      }
   }

   buildAccountForm() {
      this.formGroupAccount = this.formBuilder.group({
         firstName: [this.profile!.firstName || '', [Validators.required]],
         lastName: [this.profile!.lastName || '', [Validators.required]]
      });
   }

   buildInvitationForm() {
      this.formGroupInvitation = this.formBuilder.group({
         code: [null, [Validators.required]]
      });
   }

   async saveAccountData() {
      if (this.formGroupAccount.invalid) {
         this.formGroupAccount.markAllAsTouched();
         return;
      }

      let firstName: string = this.formGroupAccount.value['firstName'].trim();
      let lastName: string = this.formGroupAccount.value['lastName'].trim();

      if (firstName.length == 0 || lastName.length == 0) {
         this.notificationService.show('Name', 'Please provide valid information', 'warning');
         return;
      }

      let displayName: string = `${firstName} ${lastName}`;
      const updateAuthNameProm = this.authService.updateUserName(displayName);

      this.profile = this.profile as Profile & FirestoreDocument;
      this.profile.firstName = firstName;
      this.profile.lastName = lastName;

      const updateProfileProm = this.profileService.patch(this.profile._id, {
         firstName,
         lastName
      });

      this.isAccountUpdating = true;

      try {
         await Promise.all([updateAuthNameProm, updateProfileProm]);
         this.showAccountForm(false);
         localStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE_DATA, JSON.stringify(this.profile));
         this.setUserName();
      } catch (error: any) {
         this.notificationService.show('Profile', error.message, 'warning');
      } finally {
         this.isAccountUpdating = false;
      }
   }

   showAccountForm(show: boolean) {
      document.getElementById('accountDetails')!.style.display = show ? '' : 'none';
      this.hideMainScrollBar(show);
   }

   //#region Presentations

   private async listPresentations() {
      const organizationProfiles: ProfileNameEmail[] = window[
         'roomListComponentReference'
      ].zone.run(() => window['roomListComponentReference'].getOrganizationProfiles());

      const organization = localStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE_CURRENT_ORGANIZATION_ID);
      if (!organization) return;

      const [presentations, sharedPresentationsWithMe, sharedPresentationsByOwner] =
         await Promise.all([
            lastValueFrom(this.presentationService.list(organization)),
            lastValueFrom(this.presentationService.listSharedPresentations(organization)),
            lastValueFrom(
               this.presentationService.listSharedPresentationsByOwner(
                  organization,
                  this.profile!._id!
               )
            )
         ]);

      this.sharedPresentationsWithMe = sharedPresentationsWithMe.data || [];
      this.presentations = presentations.data.map(p => ({ ...p, recipients: [] }));

      for (const presentation of this.presentations) {
         const sharedPresentations = sharedPresentationsByOwner.data.filter(
            p => p.presentation == presentation._id
         );

         if (sharedPresentations.length > 0) {
            const recipientsData = sharedPresentations.map(p => {
               const foundProfile = organizationProfiles.find(op => op._id == p.recipient);
               if (foundProfile) {
                  return {
                     sharedPresentationId: p._id,
                     name: `${foundProfile.firstName || '-'} ${foundProfile.lastName || '-'}`,
                     email: foundProfile.email || '-'
                  };
               } else {
                  return { sharedPresentationId: '', name: 'not found', email: 'not found' };
               }
            });

            presentation.recipients = recipientsData;
         }
      }
   }

   async showPresentations(show: boolean) {
      document.getElementById('presentations')!.style.display = show ? '' : 'none';
      show ? await this.listPresentations() : (this.presentations = []);
      this.hideMainScrollBar(show);
   }

   async sharePresentations(presentationId: string) {
      window['roomListComponentReference'].zone.run(() =>
         window['roomListComponentReference'].sharePresentation(presentationId)
      );
      this.showPresentations(false);
   }

   displayRecipients(show: boolean, presentation: PresentationViewModel | undefined = undefined) {
      document.getElementById('presentation-recipients')!.style.display = show ? '' : 'none';
      document.getElementById('presentations')!.style.display = show ? 'none' : '';
      this.selectedPresentation = presentation;
   }

   removeSharedPresentationRecipient(id: string) {
      window['roomListComponentReference'].zone.run(() =>
         window['roomListComponentReference'].openConfirmDialog(
            'Remove Recipient',
            'Are you sure you want to remove the recipient?',
            async () => {
               this.notificationService.show('Linkroom', 'Removing...', 'info');
               await lastValueFrom(this.presentationService.deleteSharedPresentation(id));
               this.notificationService.show('Linkroom', 'Removed', 'success');
               let index = this.selectedPresentation?.recipients.findIndex(
                  r => r.sharedPresentationId == id
               );
               this.selectedPresentation?.recipients.splice(index!, 1);
            }
         )
      );
   }

   removeSharedPresentation(sharedPresentation: PresentationViewModel, index: number) {
      window['roomListComponentReference'].zone.run(() =>
         window['roomListComponentReference'].openConfirmDialog(
            'Shared Presentation',
            'Are you sure you want to remove the shared presentation?',
            async () => {
               this.notificationService.show('Linkroom', 'Removing...', 'info');
               const currentOrganizationId: string = window['roomListComponentReference'].zone.run(
                  () => window['roomListComponentReference'].getCurrentOrganizationId()
               );

               const existingResp = await lastValueFrom(
                  this.presentationService.getSharedPresentation(
                     currentOrganizationId,
                     sharedPresentation._id,
                     this.profile!._id!
                  )
               );

               await lastValueFrom(
                  this.presentationService.deleteSharedPresentation(existingResp.data._id)
               );

               this.notificationService.show('Linkroom', 'Removed', 'success');
               this.sharedPresentationsWithMe.splice(index, 1);
            }
         )
      );
   }

   //#endregion

   //#region Support Requests

   private async listAccessRequests() {
      const user = await this.authService.getCurrentUserAsync();
      if (!user) return;

      const requests = await lastValueFrom(this.accessRequestService.list(user.uid));
      this.accessRequests = requests.data || [];
   }

   async showSupportRequests(show: boolean) {
      document.getElementById('supportRequests')!.style.display = show ? '' : 'none';
      show ? await this.listAccessRequests() : (this.accessRequests = []);
      this.hideMainScrollBar(show);
   }

   formatDuration(seconds: number) {
      if (seconds % (60 * 60 * 1000) === 0) {
         const hours = seconds / (60 * 60 * 1000);
         return `${hours}h`;
      } else if (seconds % (60 * 1000) === 0) {
         const minutes = seconds / (60 * 1000);
         return `${minutes}m`;
      } else {
         return `${seconds / 1000}s`;
      }
   }

   async updateAccessRequest(id: string, status: 'approved' | 'denied') {
      const user = await this.authService.getCurrentUserAsync();
      if (!user) return;

      await lastValueFrom(this.accessRequestService.update(id, user.uid, status));
      const title = this.translateService.instant('home-nav-bar.support.title');
      const stat = this.translateService.instant(`home-nav-bar.support.${status}`);

      const message = this.translateService.instant('home-nav-bar.support.message', {
         status: stat
      });

      this.notificationService.show(
         title,
         message,
         status === 'approved' ? 'success' : 'error',
         5000
      );

      this.showSupportRequests(false);
   }

   //#endregion

   showInvitationForm(show: boolean) {
      document.getElementById('invitationForm')!.style.display = show ? '' : 'none';
      this.hideMainScrollBar(show);
   }

   async logout() {
      await this.authService.signOut();
      location.assign('/sign-in');
      // this.router.navigate(['/sign-in']);

      // this.router.navigate(['/sign-in']).then((resp) => {
      //   location.reload();
      // });
   }

   toggleAccountOptions() {
      this.showAccountOptions = !this.showAccountOptions;
   }

   invalidFieldAccount(element: any) {
      return isInvalidField(this.formGroupAccount, element);
   }

   invalidMessageAccount(element: any) {
      return getInvalidFieldMessage(this.formGroupAccount, element);
   }

   invalidFieldInvitation(element: any) {
      return isInvalidField(this.formGroupInvitation, element);
   }

   invalidMessageInvitation(element: any) {
      return getInvalidFieldMessage(this.formGroupInvitation, element);
   }

   public getInitials(name: string) {
      return getInitials(name);
   }
}
