import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Result } from '../../app/models/result.model';
import { ENDPOINTS } from '../../app/utils/constants';
import { environment } from '../../environments/environment';
import {
   PresentationDto,
   PresentationViewModel,
   SharedPresentationDto,
   SharedPresentationViewModel
} from '../models/presentation.model';

@Injectable({
   providedIn: 'root'
})
export class PresentationService {
   constructor(private http: HttpClient) {}

   add(dto: PresentationDto) {
      return this.http.post<Result<string>>(`${environment.api}${ENDPOINTS.presentations}`, dto);
   }

   archive(id: string) {
      return this.http.patch<Result>(`${environment.api}${ENDPOINTS.presentations}/${id}`, null);
   }

   delete(id: string) {
      return this.http.delete<Result>(`${environment.api}${ENDPOINTS.presentations}/${id}`);
   }

   get(id: string) {
      return this.http.get<Result<PresentationViewModel>>(
         `${environment.api}${ENDPOINTS.presentations}/${id}`
      );
   }

   getByRoomId(roomId: string) {
      return this.http.get<Result<PresentationViewModel[]>>(
         `${environment.api}${ENDPOINTS.presentations}/list-by-room/${roomId}`
      );
   }

   list(organization: string) {
      let params = new HttpParams();
      params = params.set('organization', organization);

      return this.http.get<Result<PresentationViewModel[]>>(
         `${environment.api}${ENDPOINTS.presentations}`,
         { params }
      );
   }

   update(id: string, dto: PresentationDto) {
      return this.http.put<Result>(`${environment.api}${ENDPOINTS.presentations}/${id}`, dto);
   }

   addSharedPresentation(dto: SharedPresentationDto) {
      return this.http.post<Result<string>>(
         `${environment.api}${ENDPOINTS.presentations}${ENDPOINTS.shared_presentations}`,
         dto
      );
   }

   listSharedPresentations(organization: string) {
      let params = new HttpParams();
      params = params.set('organization', organization);

      return this.http.get<Result<PresentationViewModel[]>>(
         `${environment.api}${ENDPOINTS.presentations}${ENDPOINTS.shared_presentations}`,
         { params }
      );
   }

   getSharedPresentation(organization: string, presentation: string, recipient: string) {
      return this.http.get<Result<SharedPresentationViewModel>>(
         `${environment.api}${ENDPOINTS.presentations}${ENDPOINTS.shared_presentations}/${organization}/${presentation}/${recipient}`
      );
   }

   listSharedPresentationsByOwner(organization: string, owner: string) {
      let params = new HttpParams();
      params = params.set('organization', organization);
      params = params.set('owner', owner);

      return this.http.get<Result<SharedPresentationViewModel[]>>(
         `${environment.api}${ENDPOINTS.presentations}${ENDPOINTS.shared_presentations}/by-owner`,
         { params }
      );
   }

   deleteSharedPresentation(id: string) {
      return this.http.delete<Result>(`${environment.api}${ENDPOINTS.presentations}${ENDPOINTS.shared_presentations}/${id}`);
   }
}
