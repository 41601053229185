import { Component, OnInit } from '@angular/core';

import { Language } from '../../../models/language.model';
import { AuthService } from '../../../services/auth.service';
import { I18nService } from '../../../services/i18n.service';

@Component({
   selector: 'app-home-layout',
   templateUrl: './home-layout.component.html',
   styleUrls: ['./home-layout.component.css'],
})
export class HomeLayoutComponent implements OnInit {
   countdown = '00:00:00';
   currentLanguage!: string;
   languages: Language[] = [];
   viewingAs?: string;

   private end = 0;

   constructor(private authService: AuthService, private i18nService: I18nService) { }

   ngOnInit(): void {
      this.initLangs();

      this.authService.ticket$.subscribe((ticket) => {
         this.viewingAs = ticket ? ticket.email : undefined;
         this.end = ticket ? ticket.end : 0;
      });

      setInterval(() => {
         this.updateCountdown();
      }, 1000);
   }

   onChange(lang: string) {
      this.i18nService.setLocale(lang);
      localStorage.setItem('lang', lang);
      this.currentLanguage = lang;
   }

   private formatDigits(value: number) {
      return value.toString().padStart(2, '0');
      // return value < 10 ? `0${value}` : value;
   }

   private initLangs() {
      this.languages = this.i18nService.languages;
      const localLang = localStorage.getItem('lang');

      if (!localLang) {
         this.currentLanguage = this.i18nService.languages[0].value;
         return;
      }

      const lang = this.languages.find((x) => x.value === localLang);

      if (lang) {
         this.onChange(lang.value);
      } else {
         this.currentLanguage = this.i18nService.languages[0].value;
      }
   }

   private updateCountdown() {
      if (!this.viewingAs) return;

      const now = Date.now();
      const remaining = this.end - now;

      if (remaining <= 0) {
         this.countdown = '00:00:00';
         window.location.reload();
      } else {
         const hours = Math.floor(remaining / (1000 * 60 * 60));
         const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
         const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
         this.countdown = `${this.formatDigits(hours)}:${this.formatDigits(minutes)}:${this.formatDigits(seconds)}`;
      }
   }
}
