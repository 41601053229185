import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';

import { getAuth, signInAnonymously, updateProfile } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, lastValueFrom, Observable, Subject, take } from 'rxjs';

import { environment } from '../../environments/environment';
import { Result } from '../models/result.model';
import { SupportAccessViewModel } from '../models/support-access.model';
import { SupportAccessService } from '../services/support-access.service';
import { ENDPOINTS } from '../utils/constants';
import { FirebaseAuthMethod } from '../utils/enums';

@Injectable({
   providedIn: 'root'
})
export class AuthService {
   // public user$: Observable<firebase.User | null> | undefined;
   public user: firebase.User | null = null;

   private ticket = new BehaviorSubject<SupportAccessViewModel | undefined>(undefined);
   public ticket$ = this.ticket.asObservable();

   constructor(
      private afAuth: AngularFireAuth,
      private afs: AngularFirestore,
      private http: HttpClient,
      private router: Router,
      private supportAccessService: SupportAccessService,
      private db: AngularFireDatabase
   ) {
      this.router.events.subscribe(event => {
         // If the user has not signed in, capture the ticket and store it
         if (
            (event instanceof NavigationCancel || event instanceof NavigationEnd) &&
            event.url.startsWith('/home/rooms?ticket=')
         ) {
            const url = new URL(event.url, window.location.origin);
            localStorage.setItem('ticket', url.searchParams.get('ticket') || '');
         }
      });
   }

   async updateClientSessionId(profileId: string, clientSessionId: string) {
      //    await this.db.database.ref('user-active-tokens').child(meetingId).set({ isHostInRoom, isSupportInRoom });
      return await this.afs.collection('sessions').doc(profileId).set({ clientSessionId });
   }

   listenClientSessionIdUpdates(profileId: string) {
      return this.afs.collection('sessions').doc(profileId).valueChanges();
   }

   async getTicket() {
      if (this.ticket.value) return this.ticket.value;

      const id = localStorage.getItem('ticket');
      if (!id) return undefined;

      const ticket = await lastValueFrom(this.supportAccessService.get(id));

      if (!ticket.data || ticket.data.end < Date.now()) {
         this.ticket.next(undefined);
         return undefined;
      }

      this.ticket.next(ticket.data);
      return ticket.data;
   }

   getCurrentUser() {
      if (this.user == null) {
         this.user = firebase.auth().currentUser;
      }

      return this.user;
   }

   async getCurrentUserAsync(): Promise<firebase.User | null> {
      return new Promise(async (resolve, reject) => {
         if (this.user) {
            resolve(this.user);
         } else {
            this.afAuth.onAuthStateChanged(
               (user: firebase.User | null) => {
                  this.user = user;
                  resolve(user);
               },
               err => {
                  reject(err);
               }
            );
         }
      });
   }

   signIn(email: string, password: string): Promise<void> {
      return new Promise<void>(async (resolve, reject) => {
         try {
            this.afAuth.signInWithEmailAndPassword(email, password).then(
               (credential: firebase.auth.UserCredential) => {
                  this.user = credential.user;
                  resolve();
               },
               err => reject(err)
            );
         } catch (error) {
            reject(error);
         }
      });
   }

   signUp(email: string, password: string) {
      return this.afAuth.createUserWithEmailAndPassword(email, password);
   }

   signOut() {
      return new Promise<void>(async (resolve, reject) => {
         try {
            await Promise.all([this.afAuth.signOut(), firebase.auth().signOut()]);
            this.user = null;
            resolve();
         } catch (error) {
            reject();
         }
      });
   }

   async signInSocialNetwork(
      authMethod: FirebaseAuthMethod,
      isDesktop: boolean
   ): Promise<firebase.auth.UserCredential | undefined> {
      const currentUser = await this.afAuth.currentUser;

      if (currentUser) {
         await Promise.all([this.afAuth.signOut(), firebase.auth().signOut()]);
      }

      let provider; //: firebase.auth.GoogleAuthProvider | firebase.auth.FacebookAuthProvider;

      switch (authMethod) {
         case FirebaseAuthMethod.FACEBOOK:
            provider = new firebase.auth.FacebookAuthProvider();
            break;

         case FirebaseAuthMethod.GOOGLE:
            provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/plus.login');
            break;
         default:
            break;
      }

      if (provider) {
         provider.setCustomParameters({ redirect_uri: environment.authDomain });
         if (isDesktop) {
            this.afAuth.signInWithRedirect(provider);
            return undefined;
         } else {
            return this.afAuth.signInWithPopup(provider);
         }
      } else {
         return undefined;
      }
   }

   getRedirectedResult() {
      return this.afAuth.getRedirectResult();
   }

   sendPasswordResetEmail(email: string) {
      //  return this.auth.sendPasswordResetEmail(email);
      return this.http.post<Result<any>>(`${environment.api}${ENDPOINTS.reset_password}`, {
         email
      });
   }

   getEmailAuthProviders(email: string) {
      return this.http.get<Result<any>>(`${environment.api}${ENDPOINTS.auth_providers}/${email}`);
   }

   updatePassword(oob: string, newPassword: string) {
      return this.afAuth.confirmPasswordReset(oob, newPassword);
   }

   updateUserName(displayName: string) {
      const auth = getAuth();
      return updateProfile(auth.currentUser!, { displayName });
   }

   updateUserPhotoUrl(photoURL: string) {
      const auth = getAuth();
      return updateProfile(auth.currentUser!, { photoURL });
   }

   signInAnonymously() {
      const auth = getAuth();
      return signInAnonymously(auth);
   }

   checkTokenStatus(): Observable<boolean> {
      return this.http.get<boolean>('http://localhost:3000/auth/check-token-status');
      // Replace with your backend API endpoint
   }
}
